<template>
  <div id="app">
    <!--顶部菜单栏-->
    <NavbarLayout></NavbarLayout>
    <!--主体内容-->
    <AppMain class="main-container"></AppMain>
    <!--底部菜单栏-->
    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
import NavbarLayout from '@/layouts/NavbarLayout'
import AppMain from '@/layouts/AppMain'
import FooterLayout from '@/layouts/FooterLayout'

export default {
  name: 'App',
  components: {
    NavbarLayout,
    AppMain,
    FooterLayout
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
 min-width: 120px !important;
}
</style>
