<template>
  <footer class="footer">
    <div class="container">
      <ul>
        <li>
          <h5>{{$t('common.Solution')}}</h5>
          <span @click="$gotoPage('/solution/project')">{{$t('common.projectManagement')}}</span>
          <span @click="$gotoPage('/solution/development')">{{$t('common.management')}}</span>
          <span @click="$gotoPage('/solution/outsourcing')">{{$t('common.OutsourcingManagement')}}</span>
          <span @click="$gotoPage('/solution/productLifecycle')">{{$t('common.ProductLifecycleManagement')}}</span>
        </li>
        <li>
          <h5>{{$t('common.product')}}</h5>
          <!-- <span @click="$gotoPage('/products/platform')">信创一体化办公平台</span> -->
            <span @click="$gotoPage('/products/operation')">{{$t('common.Dataproduct')}}</span>
            <span @click="$gotoPage('/products/blockchain')">{{$t('common.Blockchain')}}</span>
            <span @click="$gotoPage('/products/bigdata')">{{$t('common.Realtimebig')}}</span>
            <span @click="$gotoPage('/products/hroutsourcing')">{{$t('common.HRoutsourcingplatform')}}</span>
        </li>
        <li>
          <h5>{{$t('common.dynamic')}}</h5>
         <span @click="$gotoPage('/aboutUs/profile')">{{$t('common.CompanyProfile')}}</span>
          <span @click="$gotoPage('/aboutUs/advantage')">{{$t('common.Companyadvantages')}}</span>
          <span @click="$gotoPage('/aboutUs/qualification')">{{$t('common.Companyqualification')}}</span>
          <span @click="$gotoPage('/aboutUs/news')">{{$t('common.Companyinformation')}}</span>
          <span @click="$gotoPage('/aboutUs/contact')">{{$t('common.contactus')}}</span>
          <span @click="$gotoPage('/aboutUs/join')">{{$t('common.Joinus')}}</span>
        </li>
        <li>
          <h5>{{$t('common.servicesupport')}}</h5>
          <span @click="$gotoPage('/service/management')">{{$t('common.ManagementConsulting')}}</span>
          <span @click="$gotoPage('/service/enterprise')">{{$t('common.Customprograms')}}</span>
          <span @click="$gotoPage('/service/dev')">{{$t('common.applicationdevelopment')}}</span>
        </li>
        <li>
          <h5>{{$t('common.Strongalliance')}}</h5>
          <a href="http://www.wiilead.com" target="_black" class="dribble">{{$t('common.MicroLitchiTechnology')}}</a>
        </li>
        <li class="ft-info">
          <span><img src="../assets/images/footer/1.png" /> 158-7551-4377</span>
          <span><img src="../assets/images/footer/2.png" /> 10477387</span>
          <span
            ><img src="../assets/images/footer/3.png" /> xiaojing.xue@wiilead.com</span
          >
          <!-- <a href="http://www.buildpmo.com" target="_blank"
            ><img src="../assets/images/footer/4.png" /> www.buildpmo.com</a
          > -->
          <a href="https://beian.miit.gov.cn" target="_blank" style="color: #007bff;"
            ><img src="../assets/images/footer/4.png" /> 粤ICP备18123610号</a
          >
        </li>
        <li class="ft-code">
          <i class="codeImg"></i>
          <p>{{$t('common.Payattention')}}</p>
        </li>
      </ul>
      <div class="footer-top-text">
        <p>
          Copyright © 2013 ~ <span id="timeYear">{{ $moment(new Date()).format("YYYY") }}</span> Oriental DaTang Information Technology Co.,Ltd.<br />
          {{$t('common.Room415')}}<br />
          {{$t('common.namefirm')}}
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
