<template>
  <header class="header">
    <div class="container">
      <a href="/" :title="$t('common.PM')" class="head-logo">
        <div class="logoImg"></div>
        <div class="logoTitle">
          <span>{{$t('common.OrientalDatang')}}</span>
          <font>{{$t('common.PM')}}</font>
        </div>
      </a>
      <div class="nav">
        <el-menu default-active="1" class="el-menu-demo" mode="horizontal">
          <el-menu-item index="1"><a href="/home" style="text-decoration:none;">{{$t('common.Home')}}</a></el-menu-item>
          <el-submenu index="2">
            <template slot="title">{{$t('common.product')}}</template>
            <el-menu-item index="2-1" @click="$gotoPage('/products/operation')" width="120">{{$t('common.Dataproduct')}}</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">{{$t('common.Solution')}}</template>
            <el-menu-item index="3-1" @click="$gotoPage('/solution/project')">{{$t('common.projectManagement')}}</el-menu-item>
            <el-menu-item index="3-2" @click="$gotoPage('/solution/development')">{{$t("common.management")}}</el-menu-item>
            <el-menu-item index="3-3" @click="$gotoPage('/solution/outsourcing')">{{$t('common.OutsourcingManagement')}}</el-menu-item>
            <el-menu-item index="3-4" @click="$gotoPage('/solution/productLifecycle')">{{$t('common.ProductLifecycleManagement')}}</el-menu-item>
          </el-submenu>
          <el-menu-item index="4" @click="$gotoPage('/case/customer')">{{$t('common.customercase')}}</el-menu-item>
          <el-submenu index="5">
            <template slot="title">{{$t('common.servicesupport')}}</template>
            <el-menu-item index="5-1" @click="$gotoPage('/service/management')">{{$t('common.ManagementConsulting')}}</el-menu-item>
            <el-menu-item index="5-2" @click="$gotoPage('/service/enterprise')">{{$t('common.Customprograms')}}</el-menu-item>
            <el-menu-item index="5-3" @click="$gotoPage('/service/dev')">{{$t('common.applicationdevelopment')}}</el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">{{$t('common.AboutUs')}}</template>
            <el-menu-item index="6-1" @click="$gotoPage('/aboutUs/profile')">{{$t('common.CompanyProfile')}}</el-menu-item>
            <el-menu-item index="6-2" @click="$gotoPage('/aboutUs/advantage')">{{$t('common.Companyadvantages')}}</el-menu-item>
            <el-menu-item index="6-3" @click="$gotoPage('/aboutUs/qualification')">{{$t('common.Companyqualification')}}</el-menu-item>
            <el-menu-item index="6-4" @click="$gotoPage('/aboutUs/news')">{{$t('common.Companyinformation')}}</el-menu-item>
            <el-menu-item index="6-5" @click="$gotoPage('/aboutUs/contact')">{{$t('common.contactus')}}</el-menu-item>
            <el-menu-item index="6-6" @click="$gotoPage('/aboutUs/join')">{{$t('common.Joinus')}}</el-menu-item>
          </el-submenu>
        </el-menu>
        <div style="color:#000;">
          <el-button type="text" @click="handleSetLanguage" :disabled="add=='zh'">中</el-button>/<el-button style="margin-left: 0px;" :disabled="add=='en'" type="text" @click="handleSetLanguage">English</el-button>
        </div>
        <!-- <ul>
          <li style="background: #000;"><a href="/"><div style="color: #606266;padding: 0 22px;font-size: 16px;">首页</div></a></li>
          <li>
            <el-dropdown>
              <span class="el-dropdown-link">
                产品<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="$gotoPage('/products/platform')">信创一体化办公平台</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/products/operation')">数据运营中台</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/products/blockchain')">区块链联盟链工具平台</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/products/bigdata')">实时大数据工具平台</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/products/hroutsourcing')">人力外包平台</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <el-dropdown>
              <span class="el-dropdown-link">
                解决方案<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="$gotoPage('/solution/project')">项目管理</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/solution/development')">研发管理</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/solution/outsourcing')">外包管理</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li><div @click="$gotoPage('/case/customer')">客户案例</div></li>
          <li>
            <el-dropdown>
              <span class="el-dropdown-link">
                服务支持<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="$gotoPage('/service/management')">管理咨询</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/service/enterprise')">企业内训</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/service/dev')">应用开发</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <el-dropdown>
              <span class="el-dropdown-link">
                关于我们<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="$gotoPage('/aboutUs/profile')">公司简介</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/aboutUs/advantage')">公司优势</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/aboutUs/qualification')">公司资质</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/aboutUs/news')">公司资讯</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/aboutUs/contact')">联系我们</div></el-dropdown-item>
                <el-dropdown-item><div @click="$gotoPage('/aboutUs/join')">加入我们</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul> -->
      </div>
        
        
    </div>
    
        
  </header>
</template>
<script>
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      add:Cookies.get('language') || 'zh',
    };
  },
  computed: {
    
  },
  methods: {
    gotoPage(param) {
      console.log(param);
    },
    handleSetLanguage() {
      if(Cookies.get('language')=='en'){
        this.$i18n.locale = 'zh'
        Cookies.set('language', 'zh')
        this.add=Cookies.get('language')
      }else{
        this.$i18n.locale = 'en'
        Cookies.set('language', 'en')
        this.add=Cookies.get('language')
      }
      
      this.$notify({
        title: this.$t('common.success'),
        message: this.$t('common.switchSuccess'),
        type: 'success',
        duration: '2000'
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu__item{
  padding: 0;
  div{
    padding: 0 20px;
  }
  
}
.el-button--text{
  color:#000,
}
.header >>> .el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
 min-width: 110px !important;
}
.header {
  .el-menu.el-menu--horizontal{
    border: none;
  }
  .nav .el-menu-item,.nav a{
    font-size: 16px;
  }
}
</style>