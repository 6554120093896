/* eslint-disable no-dupe-keys */
export default {
  common: {
    freetrial:'Free trial',
    Microservice:'Enterprise level project management platform based on microservice',
    consult:'The first ecological project management service provider with process consulting, tools and landing services',
    collaborative:'Micro collaborative, lightweight and flexible project management based on mobile Internet technology.',
    dataServices:'Data service and intelligent application provider',
    dataAssert:'It is our service tenet to turn data assets into means of production and explore the value of data.',
    OrientalDatang:'Oriental Datang',
    success: 'Success',
    switchSuccess: 'Switch Success',
    PM:'Focus on project management value',
    Home:'Home',
    product:'Product',
    Dataproduct:'Data Operation Middle Platform',
    Solution:'Solution',
    projectManagement:'Project Management',
    management:'R&D Management',
    OutsourcingManagement:'Outsourcing Management',
    ProductLifecycleManagement:'Product Lifecycle Management',
    CompanyProfile:'Company Profile',
    Companyadvantages:'Company Advantages',
    Companyqualification:'Company Qualification',
    Companyinformation:'Company Information',
    contactus:'Contact Us',
    Joinus:'Join Us',
    customercase:'Customer Case',
    servicesupport:'Service Support',
    AboutUs:'About Us',
    ManagementConsulting:'Management Consulting',
    MC:'MC',
    Customprograms:'Custom Programs',
    applicationdevelopment:'Application Development',
    Customeroriented:'Customer oriented, cooperation and win-win',
    Focusingoncustomers:'Focusing on customers, practicing the concept of "exclusive service, cooperation and sharing", we have gathered nearly 100 expert teams who once worked in banks, securities and other companies',
    Best:'Best Project Management Practices of Top 100 Enterprises',
    expertpanel:'All the expert groups are from top tier enterprises, IBM, Huawei, CMB, China Resources and China Mobile, with more than 15 years of work experience',
    finance:'Finance',
    manufacture:'Manufacture',
    Medical:'Medical',
    communicate:'Communicate',
    project:'Project',
    more:'More',
    servicefirst:'Do not sell tools, only service, service first',
    Customized:'Customized, open source, cooperative development, and launched solutions such as project portfolio management, technology outsourcing management, ITSM management, and management cockpit',
    projectmanagement:'project management',
    VM:'VM ',
    ALM:'ALM R&D Management',
    assetmanagement:'asset management',
    PM:'PM APP',
    Datamiddleplatform:'Data middle platform',
    Commonchoice:'Common choice of millions of enterprises',
    usingit:"They're using it",
    text1:'Comprehensively improved the maturity of project management of CMB Operation Center, and realized the whole process control from the decomposition of strategic objectives to the specific implementation of the project.',
    text2:'In 2012, China Merchants Bank introduced this project management system, which was widely used in the operation center in 2012. It changed the original way of project management and reporting through Word, Excel and Powerpoint, and realized the electronic process of the whole process of project management. The first phase system mainly introduced basic modules such as personal space and project management...',
    viewmore:'view more',
    text3:'The Science and Technology Development Department of Hankou Bank improved the project management process and significantly improved the organizational efficiency.',
    text4:'Since the comprehensive introduction of the project management system, the science and technology Development Department of Hankou Bank has comprehensively improved the organizational efficiency. The project management has changed from disorderly to orderly, from paper to paperless, from only focusing on results to monitoring each process, from top to senior management to project managers, and managers at all levels have felt the convenience and ease of management. Project members also fully feel the orderly and standardized process of project management and control...',
    text5:'Gradually improve the project management system, and fully realize the closed-loop management of the whole life cycle of a single project, supported by project management tools.',
    text6:'Bohai International Trust Company introduced our project management system in the way of consulting+tools. While improving the project management system, it built the system, and finally formed a perfect connection between the system and the system. Project management runs through the five process groups of startup, planning, implementation, closure and monitoring, fully realizing the process and electronic management of single project management, greatly improving the efficiency of project implementation, and reducing project costs, At the same time...',
    text7:"Cross ecological coordination and multi project linkage perfectly present LeEco's unique ecosystem",
    text8:'In December 2015, LeTV Group (including overseas companies) fully introduced the project management system, which was used throughout the Group for customs clearance, and realized single project management, project group management, risk management, problem management, strategic management, etc. The biggest feature of this system is that it comprehensively meets the unlimited expansion of project set in the actual business development of Internet companies, and the project plan supports unlimited expansion...',
    text9:'Effectively realize the reasonable allocation and control of project resources to ensure the effective implementation of the project.',
    text10:"The effective control of human and material resources during the project implementation of BAIC Group has become the company's strategic goal. Through the introduction of this system, the whole life cycle process management and control is achieved in project management, especially in the reasonable allocation and allocation of human resources and material resources required by the project. In the process of project initiation, the project manager initiates a resource application to the resource manager. Resources can be used in the project after allocation...",
    text11:'Please fill in the information correctly and we will contact you as soon as possible',
    name:'name',
    phone:'phone',
    email:'email',
    position:'position',
    companyname:'company name',
    companyaddress:'company address',
    remark:'remark',
    Case:'Request a trial',
    pleaseenter:'please enter',
    Blockchain:'Blockchain Alliance Chain Tool Platform',
    Realtimebig:'Real time big data tool platform',
    HRoutsourcingplatform:'HR outsourcing platform',
    dynamic:'dynamic',
    Strongalliance:'Strong alliance',
    MicroLitchiTechnology:'Micro Litchi Technology',
    Payattention:'Pay attention to the public number for more information',
    Room415:'Room 415, Maker Mansion, No. 1018 Chaguang Road, Nanshan District, Shenzhen, Guangdong',
    namefirm:'Shenzhen Dongfang Datang Information Technology Co., Ltd',
    Newgenerationenterprise:'New generation enterprise management digital transformation solution provider',
    DataOperationCenter:'Data Operation Center - Product Introduction',
    text12:'The operation middle office collects, calculates, stores and processes massive data through data technology, unifies standards and caliber, unifies data and forms standard data for storage, forming a big data asset layer. At the same time, the middle office integrates data management, data development and business intelligence (BI) to form a one-stop data operation platform, which improves the ability of system risk early warning and decision support.',
    ProductValue:'Data Operation Center - Product Value',
    Comprehensive:'Comprehensive review of data asset construction',
    Globaldatacenter:' Global data center',
    Implementation:'Implementation strategy and organizational guarantee',
    Fosterbusinessinnovation:' Foster business innovation',
    ofsoil:' of soil',
    Dataassetmanagement:'Data asset management ',
    Goonline:'Go online ',
    talentgrowth:'Form the cradle of data talent growth',
    Buildthrough:'Build through the continuous nourishment of business ',
    Businessanddata:'Business and data ',
    ofclosedloops:'of closed loops',
    allindustries:'Data midrange applications cover all industries',
    img1:'Internet of Vehicles solution based on data middle platform - data processing',
    img2:'Internet of Vehicles solution based on data middle platform - user profile',
    img3:'Internet of Vehicles solution based on data middle platform - intelligent scenario recommendation',
    img4:'Internet of Vehicles solution based on data middle platform - fault diagnosis',
    Characteristicsofdata:'Characteristics of data operation middle office',
    successcases:'success cases',
    GuangzhouRailway:'Guangzhou Railway big data platform for statistics',
    text13:'Combined with the current situation of the railway group, the big data platform of statistics breaks the data island and brings new data value to customers.',
    MeizhouTourism:'Meizhou Tourism Big Data Platform',
    text14:"The tourism big data platform realizes the analysis of passenger flow, tourist origin, tourist attributes, tourist consumption capacity, transportation mode and real-time passenger flow distribution through the integration of the operator's own data and external data.",
    SunYat:"Sun Yat sen University new media supervision platform",
    text15:"Decision support: provide users with high-quality data sources such as crisis management and brand management, comprehensive tracking: according to the definition of users, continuously track and keep an eye on the development of potential risk events, issue early warnings: intuitively reflect the development trend of events...",
    Providecustomized:'Provide customized solutions for specific customer application scenarios',
    Difficultproblems:'Encountered a difficult problem in project management',
    ProductLifecycleManagementSubText:'Create an integrated collaborative research and development platform to achieve a full project lifecycle management solution',
    PLMProblemSolving:'PLM core problem-solving',
    PLMProblem1:'Far away from users',
    PLMProblemDesc1:'Lack of user research team capabilities, application of digital tools, and inability to gain insight into user\'s deep-seated needs',
    PLMProblem2:'Low product efficiency',
    PLMProblemDesc2:'The product line is complex and there are many customized projects; System and data silos are unable to effectively conduct financial analysis of product lines and projects',
    PLMProblem3:'Lack of core technology',
    PLMProblemDesc3:'Insufficient ability to invest in research and development, formulate technical plans, and develop technical strategies; Low conversion efficiency of technological achievements',
    PLMProblem4:'Multiple process breakpoints',
    PLMProblemDesc4:'The R&D field is not effectively connected with other fields, and there is a lack of a unified process framework',
    PLMProblem5:'Difficulty in management',
    PLMProblemDesc5:'The scale of the enterprise continues to expand, with more and more products, but the profitability is decreasing and the management complexity is increasing',
    SolutionofDongfangDatang:'Solution of Dongfang Datang',
    Unifiedtechnicalarchitecture:'Unified technical architecture',
    Unifiedapplication:'Unified application development platform to reduce development complexity and support scalability',
    Unifiedmanagement:'• Unified management and release platform (application store)',
    Opensource:'• Open source, independent and controllable',
    Improveuserperception:'Improve user perception',
    TheUI:'The UI is designed with simplicity and ease of use as the starting point to improve user perception',
    Wholeprocessplanning:'Whole process planning',
    Realizebusiness:'Realize business change from the perspective of the whole process',
    Theapplication:'• The application field is not only the superposition of traditional application functions, but also the reconstruction and integration',
    Benefitfromthreeparties:'Benefit from three parties',
    Planningshould:'• Planning should consider the benefits of customers, companies and individuals',
    Planningandprioritization:'• Planning and prioritization of application roadmap based on cost and benefit and current situation',
    Ouradvantages:'Our advantages',
    Highlyopensystem:'Highly open system',
    Thefirst:'• The first truly open source platform',
    Supporthorizontal:'• Support horizontal expansion of enterprise level processes',
    Supportpersonalized:'• Support personalized customization of existing processes',
    Convenientmicroservices:"Convenient microservices",
    Microservicemode:'• Micro service mode',
    Providemature:"• Provide mature system integration scheme",
    AI:'AI, big data',
    Effectiveapplication:'• Effective application of AI artificial intelligence',
    Intelligent:'• Intelligent performance appraisal model engine',
    Intelligentmatchmakingengine:'• Intelligent matchmaking engine',
    Flexiblecustomization:'Flexible customization',
    Applicable:'• Applicable to different scenarios and classifications',
    Variousauxiliaryfunctions:'• Various auxiliary functions',
    Applicationscenario:'Application scenario',
    RD:'R&D',
    Onestop:'One stop R&D management solution',
    Cleargrasp:"Clear grasp of project progress, fast follow-up of product bugs, multi-dimensional statistical reports, and orderly management of documents",
    finance:'Finance',
    Rapiddevelopment:'Rapid development of financial industry',
    Incombination:'In combination with the characteristics of industry demand, it provides solutions for banks, insurance, securities, Internet finance and other customers...',
    manufacture:'Manufacture',
    Manufacturing:'Manufacturing management platform',
    competitiveness:'In combination with the characteristics of industry demand, it provides solutions for banks, insurance, securities, Internet finance and other customers...',
    communication:'Communication',
    communicationfield:'Focus on communication field',
    Usecontinuous:'Use continuous technology and service innovation to accelerate the construction of communication network projects and increase the business and service scope of communication companies',
    Softwarelifecycle:'Software lifecycle',
    Demandanalysis:'• Demand analysis and design',
    Functionaldevelopment:'• Functional development test and verification',
    Launch:'• Launch',
    Operation:'• Operation and maintenance iteration',
    agiledevelopment:'agile development',
    Themanagement:'The management of "requirements", "iterations", "defects" and other links will divide the project into tasks that are easy to practice, so as to help the project implement continuously and efficiently.',
    Toolsystem:'Tool system',
    Orderlymanagement:'• Orderly management',
    Technologicalinnovation:'• Technological innovation and management change',
    continuetooptimize:'Undertake long-term goals and continue to optimize',
    Secondconsultation:'Second consultation',
    Outlinedesign:"Outline design",
    Detaileddesign:'Detailed design',
    Electronicprocesspreparation:'Electronic process preparation',
    Electronicprocess:'Electronic process',
    ALTimplementationtestonline:'ALT implementation test online',
    Letcustomers:'Let customers truly implement the system and tools of project management',
    ChinaMerchantsBank:'China Merchants Bank',
    text16:'Comprehensively improved the maturity of project management of CMB Operation Center, and realized the whole process control from the decomposition of strategic objectives to the specific implementation of the project.',
    text17:'In 2012, China Merchants Bank introduced this project management system, which was widely used in the operation center in 2012. It changed the original way of project management and reporting through Word, Excel and Powerpoint, and realized the electronic process of the whole process of project management. The first phase system mainly introduced basic modules such as personal space and project management, and realized the electronic monitoring of the whole process of project management from project establishment to project completion, After about three years of stable operation, the strategic management module, talent management module, knowledge management module, etc. have been gradually added to further improve the whole life cycle management of project management. Every year, the center will collect, extract and input the strategic planning information, and finally finalize the draft, and then decompose it layer by layer to form needs, implement them to each department, and then create projects separately, The project is gradually detailed to form specific tasks for implementation after the development of the schedule, which is fed back to the project progress after completion, and finally realizes the two-way monitoring of the strategic objectives from top to bottom and from bottom to top. Up to now, the system has been running well, providing data and tool support for the project management of the Operation Center.',
    Haier:'Haier',
    text18:'The cross department collaborative project management process is perfectly connected, helping Haier Group comprehensively improve the organizational project management level.',
    ShenzhenStockExchange:'Shenzhen Stock Exchange',
    text19:'Assisting the continuous upgrading of the engineering management system of Shenzhen Stock Exchange, and steadily supporting the engineering management of Shenzhen Stock Exchange for three years.',
    LeMall:'LeMall',
    Internet:'Internet',
    text20:"Cross ecological coordination, multi project linkage, perfect presentation of LeEco's unique ecosystem, and realization of full closed-loop life cycle process management of all projects of LeEco Group",
    HankouBank:'Hankou Bank',
    text21:'The Science and Technology Development Department of Hankou Bank improved the project management process and significantly improved the organizational efficiency.',
    LianstackCorporate:'Lianstack Corporate',
    text22:"Effectively realize the reasonable allocation and control of project resources to ensure the effective implementation of the project.",
    investment:'investment',
    Bohai:'Bohai International Trust Co. LTD',
    text23:"Gradually improve the project management system, and fully realize the closed-loop management of the whole life cycle of a single project, supported by project management tools.",
    BaicGroup:'Baic Group',
    text24:"Effectively realize the reasonable allocation and control of project resources to ensure the effective implementation of the project.",
    text25:"Formulate the management system, design and develop the science and technology outsourcing information management system, and effectively solve the practical problems of science and technology outsourcing management.",
    JilinBank:'Jilin Bank',
    summary:"summary",
    text26:"Based on the advanced project management concept in the industry and rich practical experience in project management, the consulting products shared by Dongfang Datang include: PMO construction consulting, project management system construction consulting, agile development management consulting, science and technology supervision rating support consulting, project demand management consulting, outsourcing management consulting, and project risk management consulting.",
    Specificintroduction:'Specific introduction of consultation',
    PMOconstructionconsulting:'PMO construction consulting',
    text27:"PMO construction consulting is based on the responsibilities and positioning of PMO, combined with the actual project management environment of the organization, to help the organization establish a platform type project management system, improve the quality of project management personnel, improve the project management system, and comprehensively enhance the maturity of the organization's project management.",
    Projectmanagement:'Project management system construction consultation',
    text28:'The construction of the project management system is carried out from five levels: project management methods, project management rules, project management procedures, project management templates, and project management guidelines. It involves three project management areas: project management, project management, and support management. The project management system is designed and improved in combination with CMMI requirements.',
    AgileManagementConsulting:'Agile Management Consulting',
    text29:"Agile management consulting closely focuses on agile management principles, agile project team building, and agile management framework process implementation. It provides training for relevant personnel of the organization and helps the organization build an agile management environment and system to realize the automation of agile management.",
    text30:"Based on the relevant guidance and documents issued by the CBRC and in combination with the different requirements and details of each year's supervision, the scientific and technological regulatory rating consultation conducted analysis and formulation of response plans, and assisted in on-site response and document preparation during the regulatory rating period.",
    text31:"Project demand management consulting and supervision Project demand management methods, project demand management rules, project demand management related procedures, project demand management related templates, define the demand management methods and demand application and review plans, and help the organization to achieve efficient and reasonable demand management.",
    text32:"Focusing on the requirements of the CBRC on outsourcing risks, we will help the organization establish a sound outsourcing management system and outsourcing management system planning from the four major areas of outsourcing supplier management, outsourcing personnel management, outsourcing contract management, and outsourcing risk management, so as to control outsourcing risks and improve the efficiency of outsourcing management on the premise of ensuring the compliance of the organization's outsourcing management.",
    text33:"The project risk management consultation forms three lines of defense for risk management based on the different risk management division among the executors, risk managers and risk auditors, clarifies the responsibilities and work contents of the three types of personnel, establishes a risk management system with safety, risk and audit as the basic contents, and realizes the improvement of project risk management.",
    Scientific:"Scientific and technological supervision rating consultation",
    Projectdemand:"Project demand management consulting",
    Outsourcing:"Outsourcing management consulting",
    management1 :"project risk management ",
    ECPP:'ECPP',
    text34:'Enterprise internal training is a customized training course for enterprise managers (including managers, project managers, and PMO personnel), covering 39 special contents in 6 management fields, to ensure the daily management of the enterprise and the quality and ability of the project management team',
    Trainerintroduction:'Trainer introduction',
    TeacherBao:'Teacher Bao',
    text35:"Dongfang Datang is a senior consultant who once served China Merchants Bank. Mainly responsible for the PMO management of China Merchants Bank, holding PMP, PRINCE2, ITIL, and other management certificates. He is the director of Shenzhen Blockchain Technology Research Association. Focus on the construction of project management system and the research and practice of micro service, intelligence and blockchain of project management system.",
    MissGao:'Miss Gao',
    text36:"Senior consultant of Dongfang Datang, project management consultant of China Merchants Bank, Industrial Bank, Taikang Life Insurance and other enterprises. He has many years of practical experience in project management consulting and training, as well as experience in enterprise IT project management system construction and demand management.",
    MissAn:'Miss An',
    MissWang:'Miss Wang',
    text37:'Dongfang Datang Senior Consultant, with rich project management experience, holds a PRINCE2 project management certificate. He once managed the project management system project of China Merchants Bank, the project management platform project of BAIC Motor Corporation, and the outsourcing management platform project of Bank of Guangzhou.',
    text38:'The senior consultant of Dongfang Datang, who once served China Merchants Bank, Chow Tai Fook Group, Endeavour Group and other large enterprises, focused on organizing PMO construction, project management, organization construction and outsourcing management, has rich project time experience and consulting experience, and has provided enterprise internal training services for many well-known enterprises.',
    Projectmanagement:'Project management training schedule',
    number:'number',
    Curriculumsystem:'Curriculum system',
    Includingspecial:'Including special contents',
    Suggestions:'Suggestions for training objects',
    Trainingdays:'Training days',
    management1:"management",
    projectmanager:'project manager',
    personnel:'PMO personnel',
    Personnelquality:'Personnel quality improvement',
    Development:"Thoughts on Personal Quality and Professional Development",
    Methods:"Methods to improve personal quality",
    Comprehensive:'Comprehensive evaluation of personal quality',
    qualityimprovement:"Practice of personal quality improvement",
    days:"days",
    Managementpersonnel:"Management personnel's thought establishment",
    Managementthinking:"Management thinking method",
    Ideas:"Ideas for improving management",
    Fourmanagement:"Four management skills of managers",
    Officeskills:"Office skills of management personnel",
    Guidance:"Guidance in management field",
    ManagementManager:"Management Manager",
    Managementorganization:'Management organization (team)',
    Managementandorganization:"Management and organization",
    Managementorganizationpersonnel:"Management organization personnel",
    managementinformation:"management information",
    Establishmanagement:"Establish management platform system",
    Importance:"Importance of project management",
    Pmpknowledge:"Pmp knowledge system",
    Prince2:"Prince2 Knowledge System Project Requirements Management Practice",
    ProjectCost:"Project Cost Management Practice",
    ProjectTime:"Project Time Management Practice",
    ProjectQuality:"Project Quality Management Practice",
    ProjectResource:"Project Resource Management Practice",
    ProjectCommunication:"Project Communication Management Practice",
    ProjectRisk:"Project Risk Management Practice",
    ProjectStakeholder:"Project Stakeholder Management Practice",
    Projectprocurement:"Project procurement management practice",
    ProjectConfiguration:"Project Configuration Management Practice",
    Practicalcases:"Practical cases of project management",
    AgileProject:"Agile Project Management",
    Agilemanagement:"Agile management knowledge",
    AgileManagement:"Agile Management Team",
    AgileRequirements:"Agile Requirements Management",
    AgileProcess:"Agile Process Management",
    PMOmanagement:"PMO construction management",
    PMOplanning:"PMO construction planning",
    PMOconstruction:"PMO organization construction",
    Multiproject:"Multi project and multi project information management",
    ExcellenceConstruction:"Center of Excellence Construction",
    informationmanagement:"Single project control guidance and information management",
    Outsourcer:"Outsourcer (outsourcing personnel) management",
    Note1:"Note: The training content includes 6 major curriculum systems and 39 special items.",
    AD:"AD",
    text39:"Dongfang Datang has a professional application development service team of nearly 100 people, and has long provided end-to-end application software development and maintenance services to the world's top 500 customers. With a systematic and mature methodology based on CMMI and ISO9001 quality standards and process certification, as well as years of rich project practice experience, Dongfang Datang can provide customers with full stack services including system development, platform development, APP development, project customization, etc.",
    Serviceadvantages:"Service advantages",
    text40:"Full coverage information solutions: provide solutions covering the whole industry such as finance, Internet, high-tech, telecommunications, new retail, e-commerce, etc.",
    text41:"Full chain and full life cycle development services: covering all stages of the development process, including requirements analysis, design, coding, testing, deployment and post implementation support.",
    text42:"Diversified customized development services: Dongfang Datang provides customers with diversified and specialized customized development services based on years of IT industry accumulation",
    Industrypracticecases:"Industry practice cases",
    Hotlineconsultation:"Hotline consultation",
    ConsultQQ:"Consult QQ",
    Customerserviceemail:"Customer service email",
    ConsultingWeChat:"Consulting WeChat",
    content1:"Shenzhen Dongfang Datang Information Technology Co., Ltd. (hereinafter referred to as Dongfang Datang) is a new generation enterprise management digital transformation solution provider. The company was founded in 2012 with a registered capital of 50 million yuan. It has the qualifications of national double soft enterprise, high-tech enterprise, ISO quality system and CMMI. It has provided professional services including digital platform, software products, application development, management consulting and system integration for large cross industry group enterprises and government customers such as finance, manufacturing, communications, medical care, etc., and assisted customers to build a platform based on big data Enterprise digital management platform system with blockchain and artificial intelligence as the core.",
    content2:"Dongfang Datang practices the system development idea of 'customized', relies on the self-developed",
    content3:"microservice platform, integrates the concept of risk management and control value improvement, and creates a business management engine that best fits the management thinking of Chinese enterprises. The company mainly promotes enterprise core business management solutions such as project management, outsourcing management, DevOps, CRM, SRM, RPA, contract management, performance management, measurement management, risk control and compliance management.",
    content4:'Adhering to the customer-centric business philosophy, Dongfang Datang implements the strategy of "One Body and Two Wings". The 3+3 model organically integrates technology and enterprise business scenarios, opens all core codes to customers, deeply integrates and replaces closed system management tools, creates a "self controllable" digital business management platform for customers, and helps customers move from excellence to excellence.',
    content5:"The company is headquartered in Shenzhen, with branches in Beijing, Shanghai, Wuhan, Guangzhou, Baotou, etc., with more than 1200 employees, and offices in Nanjing, Changsha, Xinjiang, Qinghai and other places.",
    // content6:"Since its establishment in 2012, Dongfang Datang has always focused on project management and experienced the transformation and upgrading from consulting services to tools. In 2020, the company name was changed to Shenzhen Dongfang Datang Information Technology Co., Ltd., which defined the ecological strategy of full openness and continuous innovation based on the micro service platform. The epidemic is raging, but the development of Dongfang Datang has not stopped. We have arranged five branches in five places, introduced more partners, forced the original backbone to start again, and cultivated new management core to gradually take over Dongfang Datang.",
    // content7:'In 2021, we will officially launch a new strategy of "One Body and Two Wings", focusing on project management to promote performance growth and technological innovation. The layout of human resource outsourcing business and digital transformation are two new business formats, and key customers are targeted to become bigger and stronger. We will work with Nanjing Branch to double the scale of human resource outsourcing on the basis of the existing scale of 100 employees. The digital transformation business mainly depends on the Guangzhou Branch, in combination with the BAT ecosystem, starting from the business scenario, planning and tool implementation, incubating the data middle platform product line, SRM, risk control platform, etc.',
    // content8:'The new strategy of "One Body, Two Wings" still focuses on deep cooperation with key customers, including BOE Group, BAIC Group, China Resources Group, China Merchants Group, Sany Group, China Coal Group, China Gas Corporation, Wuhan Rural Commercial Bank, Qinghai Rural Credit, Xinjiang Rural Credit and Taiping Insurance. We support R&D centers in Beijing, Wuhan and Shenzhen, adhere to the concept of "endless openness and continuous innovation", and take service as the fundamental point to accompany customers from excellence to excellence.',
    // content9:"In 2022, we will introduce more partners and provide you with more opportunities and platforms. Are you ready?",
    content10:'Adhering to the customer-centric business philosophy, Oriental Datang implements the strategy of "one body and two wings", is equipped with a professional and efficient service team, organically integrates technology and enterprise business scenarios through industry-university-research cooperation, opens all core codes to customers, deeply integrates and replaces closed system management tools, and creates an "independent and controllable" digital business management platform for customers to help customers from excellent to excellent.',
    Companyadvantages:"Company Advantages",
    Enterprisewitness:"Enterprise witness",
    MideaRealEstate:"Midea Real Estate",
    ChinaMerchantsGroup:"China Merchants Group",
    TaipingFinance:"Taiping Finance",
    ChinaMerchantsBank:"China Merchants Bank",
    SanyHeavyIndustry:"Sany Heavy Industry",
    Softening:"Softening",
    AltaVM:"Alta VM technology outsourcing management system",
    AltaPM:"Alta PM Project Management System",
    AltaALM:"Alta ALM R&D Management System",
    honor:"honor",
    Informationtechnology:"Information technology application and innovation",
    Memberunits:"Member units of the Committee",
    Memberof:"Member of Shenzhen Software Association",
    qualifications:"qualifications",
    CMMI3certificate:"CMMI3 certificate",
    ISO9001certificate:"ISO9001 certificate",
    Identification:"Identification of high-tech enterprises",
    patent:"patent",
    alliancechain:"Based on blockchain alliance chain",
    Science:"Science and technology outsourcing management system",
    ContractAbidingAndTrustworthyEnterprises:"3A level enterprise that adheres to contracts and keeps promises",
    MeiyunZhishuDiamondLevelPartner:"Meiyun Zhishu Diamond Level Partner",
    LaborDispatchBusinessLicense:"Labor Dispatch Business License",
    Massdata:"Mass data product compatibility and mutual recognition certificate",
    ISO20000Information:"ISO20000 Information Technology Service Management System Certificate",
    ISO27001Information:"ISO27001 Information Security Management System Certificate",
    Jushandatabase:"Product compatibility and mutual recognition certificate (Jushan database)",
    Softwareproduct:"Software product certificate",
    EnterpriseCertificate:"Software Enterprise Certificate",
    IndustryAssociation:"Member of Shenzhen Software Industry Association",
    WorkingCommittee:"Member of Information Technology Application and Innovation Working Committee",
    Kingdee:"Product Compatibility and Mutual Recognition Certificate (Kingdee)",
    ISO9001Quality:"ISO9001 Quality Management System",
    OrientalDatangPublicity:"Oriental Datang Publicity Conference - Global Data Middle Platform Scheme",
    Welcomefriendswho:"Welcome friends who have any questions about the content of this promotion~",
    LectureFull:"Oriental Datang Lecture - Full Process Management Solution",
    Thespringsowing:"The spring sowing activity was successfully completed, and the video of the propaganda content has been produced and uploaded. Welcome to the collection~",
    OutsourcingManagement1:"Oriental Datang Lecture - IT Outsourcing Management System Solution",
    text40:"The spring sowing activity was successfully completed, and the video of the propaganda content will be produced and uploaded in succession. Welcome to the collection~",
    customizedproducts:"BOE project management system: from standardization to customized products",
    text41:"On the basis of its standardized products, Dongfang Datang continues to provide BOE with professional customized demand development services. Driven by the process engine, dynamic forms are used as the business carrier to achieve the close integration of products and business and improve the overall work efficiency.",
    WuhanRuralCommercial:"Dongfang Datang helps Wuhan Rural Commercial Bank to establish a technology outsourcing management platform",
    text42:'Dongfang Datang has successfully reached friendly cooperation with Wuhan Rural Commercial Bank by virtue of the idea of "exclusive customization and enhanced implementation". Help Wuhan Rural Commercial Bank to establish a science and technology outsourcing management platform and deepen the implementation of the system with tools as the carrier.',
    WiileadnformationInnovation:"Oriental Datang Wiilead Information Innovation Project Management Platform [Promotion]",
    text43:"The Wiilead ICT project management platform realizes the overall management of the implementation process of the ICT project through the digital way. Monitor the whole process of project initiation, demand management, plan management, resource scheduling, problem risk management, project closure management, measurement management, etc.",
    domesticSaaSranking:"Top 30 domestic SaaS ranking",
    text44:"Top 30 ranking of internal SaaS, 2019 Internet Weekly; ENet Research Institute Selection Ranking",
    ManagementSystemReconstruction:"Dongfang Datang Helps China Coal Infrastructure Project Management System Reconstruction",
    text45:'Recently, relying on the professionalism, stability, flexibility, ease of use and rich implementation experience of the system, Dongfang Datang has entered into a cooperative relationship with China Coal Information Technology (Beijing) Co., Ltd. (hereinafter referred to as "China Coal") to help it build infrastructure project management',
    HowtheAssetManagement:"How the Asset Management System Solves the Business Pain Points of Enterprise Asset Management",
    text46:"With the continuous expansion of the business scope of the enterprise, the enterprise invests a lot of money every year to purchase various assets, including tangible assets and intangible assets; As time goes by, asset management also faces difficulties.",
    helpingdomesticsoftware:"Dongfang Datang became a member of the Information and Innovation Working Committee, helping domestic software innovation",
    text47:"At the time of vigorous development of information technology application and innovation, Shenzhen Dongfang Datang Information Technology Co., Ltd. (hereinafter referred to as Dongfang Datang) officially joined the Information Technology Application and Innovation Working Committee (member number: 1698), and Dongfang Datang became one of the third batch of enterprises to join the Information Innovation Working Committee in 2021.",
    KingdeeTianyan:"Kingdee Tianyan and Dongfang Datang completed product compatibility mutual certification",
    text48:"Recently, the Kingdee Apusic application server software V9.0/V10 of Shenzhen Kingdee Tianyan Computing Co., Ltd. (hereinafter referred to as Kingdee Tianyan) and the Wiilead project management platform of Shenzhen Dongfang Datang Information Technology Co., Ltd. (hereinafter referred to as Dongfang Datang) have been",
    ChineseSaaSenterpriseswithdeviation:"Chinese SaaS enterprises with deviation",
    text49:"I wanted to write an article on China's SaaS enterprises for a long time. I wanted to analyze the three sins of SaaS enterprises that have caused chaos in China's software market. I finally saw the article on Tencent's SaaS accelerator yesterday, and I think this article can be published.",
    HuaweiHongmengsystem:"Description on the adaptability of wiilead project management system APP and Huawei Hongmeng system",
    text50:"Yesterday, Huawei released the HarmonyOS system and opened the public test of some mobile phone models. Some Huawei mobile phone users want to have a fresh upgrade experience and are concerned about the adaptability of the wiilead project management system APP to the Hongmeng system. After testing, the existing Android client of the wiilead project management system can be used normally on the Hongmeng system, and the overall operation is smooth.",
    DongfangDatangHelps:"Dongfang Datang Helps Qinghai Rural Credit Project Management and Construction",
    text51:'Dongfang Datang has successfully reached friendly cooperation with Qinghai Rural Credit by virtue of the idea of "exclusive customization and enhanced implementation". Help Qinghai Rural Credit to establish a project management system and deepen the implementation of the system with tools as the carrier.',
    ImprovementSystemTraining:"Dongfang Datang Helps Wuhan Rural Commercial Software Implementation Process Management Project -- Project Manager Quality Improvement System Training",
    text52:"From 6:30 p.m. to 8:30 p.m. on September 19 to 20 p.m., the training related to the quality improvement of project managers in the conference room on the 7th floor of Wuhan Rural Commercial Building is being held in an intense and orderly manner. The training of the project manager's quality improvement system is the work content of a work package of the project manager's quality improvement topic in the software implementation process management project. The purpose is to improve the quality and knowledge level of the project manager through the training of the project manager to ensure the implementation of the project management system and the efficient use of the project management system.",
    Altaplatformunder:"Alta platform under Dongfang Datang helps optimize the project management system of Bank of Jilin",
    text53:"In order to standardize and guide the development of project management activities, optimize project management process, reduce project risks, improve project quality, promote the improvement of project management level of Bank of Jilin, and meet regulatory requirements to the greatest extent, the project management consulting service was carried out.",
    ImplementCMMISystem:"Dongfang Datang Joins Hands with Vanke Property to Implement CMMI System",
    text54:"With the increasing importance of information technology to enterprises, mature software development process management (CMMI) has become an important condition for enterprises to improve information technology management. The senior management of Vanke Property has put forward standardized requirements for the product development process, so",
    exchangebigdata:'Information Department of Shanghai Intellectual Property Exchange Center visited CMB Cloud Innovation to exchange big data',
    text55:"On the morning of February 15, the Information Department of Shanghai Intellectual Property Exchange Center visited CMB Yunchuang, a wholly-owned subsidiary of China Merchants Bank, to investigate the precision marketing solutions based on the big data platform. Zhang Ziwei, the general manager of CMB Yunchuang R&D Center, accompanied him throughout the process and shared CMB's experience in big data in retail business. Subsequently, the two sides conducted in-depth discussions and exchanges on the big data platform planning, platform mode, future prospects and other aspects of intellectual property transactions. Zhang Tao, General Manager of Shanghai Huigang Information Technology Co., Ltd., a member of Shanghai Fair, and Zhang Mingxiu, CEO of Dongfang Datang also participated in the exchange.",
    GroupProjectManagement:"Dongfang Datang Joins Hands with BAIC Group to Promote the Informatization Construction of Group Project Management",
    text56:"Based on the rich successful experience of Shenzhen Dongfang Datang Information Consulting Co., Ltd. in the field of project management consulting and construction for many years, on November 30, 2017, Dongfang Datang and BAIC Motor Corporation Limited reached a cooperation consensus on the construction of BAIC Group's project management system, and formally carried out the project construction on January 3, 2018.",
    projectmanagerteaparty:"Everyone is a project manager tea party",
    text57:"What are the responsibilities of the project manager? Management and coordination; There is no permanent project. The project is constantly changing. How to balance the cost, scope and progress? Project management, communication management, personnel management, resource management, stakeholder management",
    trainingactivities:"Self management seminar training activities",
    text58:'Wang Yangming said in his theory of mind: "To know clearly and faithfully is to do. To do well is to know." What he said is the requirement for management, the meaning of knowing is the management plan we formed in our mind, and doing is our specific measures. It seems that the unity of knowledge and practice is not a simple thing now. Many times, our plans are put forward and we feel very reasonable',
    ProjectManagementSystem:"Project Management System Construction Phase II Project Excellent Team Commendation Conference",
    text59:`Mr. Zhang Mingxiu, the general manager of the company, attended the award ceremony, reviewed and summarized the work of BOE Technology Group's project management system construction phase II implementation service project, and affirmed a series of significant achievements and progress in this project. In addition, he reiterated our mission and vision. He said, "Our mission is to provide customers with professional management tools!" He stressed that`,
    contestsuccessfully:'The 2018 China "Welcome Cup" project management speech contest successfully ended in Shenzhen',
    text60:`On December 31, 2018, the first China "New Year's Cup" project management speech contest co hosted by Dongfang Datang and Qiang Matrix successfully ended in Shenzhen. Many outstanding players performed well, including three winners of the Best Talent Award and two winners`,
    strategiccooperation:"Oriental Datang and PMI China become strategic cooperation",
    text61:"Recently, Mr. Zhang Mingxiu, CEO of Dongfang Datang, visited Mr. Chen Yongtao, the Managing Director of PMI (China), the highest leader of PMI in Greater China, in Beijing. The two took a group photo in PMI's China office",
    managementSaaS:"How far is the road of project management SaaS?",
    text62:"With the rise of big data and cloud computing, the words IaaS, PaaS, SaaS, CaaS and BaaS have become hot words from strangers. The scale of cloud computing market has grown from billions to tens of billions or even hundreds of billions. The annual double-digit growth rate has made VCs smell",
    ShenzhenHeadquarters:"Shenzhen | Headquarters",
    ThedawnofXiliStreet:"The dawn of Xili Street, Nanshan District, Shenzhen",
    CommunityChaguang:"Community Chaguang Road 1018 Maker Mansion 415",
    MsAn:"Ms. An",
    WuhanCenter:"Wuhan | R&D Center",
    HongshanDistrict:"Guanggu Avenue, Hongshan District, Wuhan City, Hubei Province",
    No62OpticsValley:"No. 62 Optics Valley Headquarters International Building 3 401B",
    MrRen:"Mr. Ren",
    Guangzhou:'Guangzhou',
    TianheDistrictGuangzhou:"No. 12, Keyun Road, Tianhe District, Guangzhou",
    Oneofthe28th:"One of the 28th and 29th floors is self-compiled No. 33",
    MrChen:"Mr. Chen",
    Beijing:"Beijing",
    YizhuangHongdaMiddle:"Yizhuang Hongda Middle Road, Daxing District, Beijing",
    Room320:"Room 320, Hongda Sanding, No. 16",
    MrHuang:"Mr. Huang",
    Hangzhou:"Hangzhou",
    ZhejiangProvince:'Qianjiang, Shangcheng District, Hangzhou, Zhejiang Province',
    BusinessCenter:"Room 1018-1, International Business Center",
    MrDai:"Mr. Dai",
    Shanghai:"Shanghai",
    MinhangDistrictShanghai:"No. 218, Luojin Road, Minhang District, Shanghai",
    Room326:"Room 326, Maiten Future City",
    MrLi:"Mr. Li",
    Baotou:"Baotou",
    AutonomousRegion:"Baotou City, Inner Mongolia Autonomous Region",
    QingshanDistrict:"Qingshan District Yucai Community 1-11",
    MsXue:"Ms. Xue",
    Shenzhen:"Shenzhen",
    Wuhan:"Wuhan",
    Frontenddevelopment:"Front end development",
    Jobresponsibilities:"Job responsibilities:",
    Responsibleforthe:"1. Responsible for the R&D of PC and mobile terminal (non native) development;",
    designrequirements:"2. Implement, maintain and optimize the WEB front-end page and business functions according to product design requirements;",
    Pagedesignbased:"3. Page design based on HTML, DIV+CSS and Javascript, write reusable front-end interface components, and accumulate the front-end component library of the company;",
    technicalarchitecture:"4. Identify potential problems of the system in the development, optimize the system function code, and actively participate in the improvement of product, function and technical architecture;",
    newtechnology:"5. Continuously improve the front-end interface of existing products and participate in the research of Web cutting-edge technology and new technology.",
    Jobrequirements:"Job requirements:",
    Atleast3years:"1. At least 3 years of front-end development experience in actual projects, able to independently complete PC and mobile terminal development tasks;",
    ProficientinHTML5CSS:"2. Proficient in HTML5CSS. Javascript, DOM, and solid Javascript foundation (scope inheritance, closure, object-oriented design, etc.);",
    Familiarwithmodularization:"3. Familiar with modularization, front-end compilation and construction tools, and familiar with webpack and other construction tools;",
    Proficientinfront:'4. Proficient in front-end optimization technology, deep understanding of component-based and modular development, proficient in using Vue and other mainstream MVVM frameworks, and familiar with interaction design;',
    FamiliarwithElementUl:"5. Familiar with ElementUl, iView, Bootstrap, Vux, WeUl and other common UI components;",
    Experienceinsmall:"6. Experience in small program, Android and IOS packaging H5 development is preferred;",
    Goodcommunication:"7. Good communication and coordination skills, and the ability to analyze and solve problems independently.",
    Javadevelopmentengineer:"Java development engineer",
    javatext1:"1. College degree or above, major in computer science, more than 3 years of development experience;",
    javatext2:"2. Have a solid foundation in java, master multithreading and design mode;",
    javatext3:"3. Skillfully use Spring Cloud, Spring boot, Mybatis, Spring security and other open source frameworks;",
    javatext4:"4. Have experience in the development and implementation of medium and large products or projects, and have a certain understanding of cache design and message queue;",
    javatext5:"5. Be familiar with TCP/IP, HTTP and other network protocols;",
    javatext6:"6. Be familiar with common Linux commands, and be able to independently deploy open source middleware and other application services;",
    javatext7:"7. Have strong pressure resistance, ideas, strong sense of responsibility, and work actively; Be good at learning and communication, challenge the unknown, and have a good team spirit;",
    javatext8:"Experience in financial projects is preferred",
    ContactTangJing:"Contact: Tang Jing",
    WeChatsynchronization:"WeChat synchronization",
    website:"website",
    Address401:"Address: 401, Floor 4, Building 3, Optics Valley Headquarters International, Hongshan District, Wuhan",
    qx:"Chinese",
    Previousarticle:"Previous article",
    Next:"Next",
  }
}