import Vue from 'vue'

const install = function (Vue) {
  Vue.prototype.$gotoPage = function (path, params) {
    console.log('router==', path, params);
    this.$router.push({ path, params })
  }
}

Vue.use(install)

