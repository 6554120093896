/* eslint-disable no-dupe-keys */
export default {
  common: {
    freetrial:'免费试用',
    Microservice:'基于微服务的企业级项目管理平台',
    consult:'首家含过程咨询、工具、落地服务的项目管理全生态服务商',
    collaborative:'以移动互联技术为基础的微协同、轻量、灵活项目管理。',
    dataServices:'数据服务与智能应用提供商',
    dataAssert:'把数据资产变成生产资料，探索数据价值是我们的服务宗旨。',
    OrientalDatang:'东方大唐',
    success: '成功',
    switchSuccess: '切换成功',
    PM:'专注项目管理价值',
    Home:'首页',
    product:'产品',
    Dataproduct:'数据运营中台',
    Solution:'解决方案',
    projectManagement:'项目管理',
    management:'研发管理',
    OutsourcingManagement:'外包管理',
    ProductLifecycleManagement:'产品生命周期管理PLM',
    CompanyProfile:'公司简介',
    Companyadvantages:'公司优势',
    Companyqualification:'公司资质',
    Companyinformation:'公司资讯',
    contactus:'联系我们',
    Joinus:'加入我们',
    customercase:'客户案例',
    servicesupport:'服务支持',
    AboutUs:'关于我们',
    ManagementConsulting:'管理咨询',
    MC:'管理咨询',
    Customprograms:'企业内训',
    applicationdevelopment:'应用开发',
    Customeroriented:'以客户为中心，合作与共赢',
    Focusingoncustomers:'以客户为中心，践行“专属服务、合作共享”的理念，聚集了近百名曾供职于银行、证券等公司的专家团队',
    Best:'TOP 100大企业的最佳项目管理实践',
    expertpanel:'专家团全部来自于TOP级的企业，IBM、华为、招行、华润、移动，工作经验均超过15年',
    finance:'金融',
    manufacture:'制造',
    Medical:'医疗',
    communicate:'通讯',
    project:'工程',
    more:'更多',
    servicefirst:'不卖工具，只卖服务，服务第一',
    Customized:'专属定制，开放源码、合作开发、推出了项目组合管理、科技外包管理、ITSM管理、管理驾驶舱等解决方案',
    projectmanagement:'PM-项目管理',
    VM:'VM-科技外包管理',
    ALM:'ALM-研发管理',
    assetmanagement:'资产管理',
    PM:'PM-项目管理APP',
    Datamiddleplatform:'数据中台',
    Commonchoice:'数百万企业的共同选择',
    usingit:'他们都在用',
    text1:'全面提升了招行运行中心项目管理成熟度，实现了从战略目标分解到项目具体实施的全流程管控。',
    text2:'2012年招商银行引进了此套项目管理系统，2012年在运行中心全面推广使用，改变了原有通过Word、Excel、PPT进行项目管理和汇报的方式，实现了项目管理全过程电子化流程化，一期系统主要引进了个人空间、项目管理等基本模块，实现了项目管理从立项到结项全过程的电子化监控...',
    viewmore:'查看更多',
    text3:'汉口银行科技开发部完善了项目管理流程，组织效率得到了显著提升。',
    text4:'汉口银行科技开发部全面引进项目管理系统以来，全面提升了组织效率，项目管理从无序化到有序化，从纸质化到无纸化，从只关注结果到监控每个过程，从上至高层管理，下至项目经理，每各层级的管理者都从中感受到了管理的便捷和轻松，项目成员也充分感受到了项目管控过程的有序和规范。通过系统项目经理实时更新项目进度...',
    text5:'逐步完善项目管理体系，以项目管理工具为支撑，全面实现单项目全生命周期闭环管理。',
    text6:'渤海国际信托公司以咨询+工具的方式引进我们的项目管理系统，在完善项目管理体系的同时，建设系统，最终体系和系统形成完美对接，项目管理贯穿启动、规划、执行、收尾和监控五大过程组，全面实现了单项目管理全闭环管理的流程化和电子化，大大提高了项目执行效率，降低了项目成本，同时通过工具积累了大量项目监控数据...',
    text7:'跨生态协同，多项目联动，完美呈现乐视独有生态系统',
    text8:'2015年12月乐视集团公司（含海外）全面引进本项目管理系统，在整个集团公司内全面退关使用，实现了单项目管理、项目群管理、风险管理、问题管理、战略管理等，本系统最大的特点是全方位满足了互联网类公司实际业务发展中项目集的无限扩大，项目计划支持不限层级扩展延伸，战略目标层层分解落实到具体项目，全面提升了乐视跨生态项目管...',
    text9:'有效实现项目资源合理配置和管控，保证项目有效执行。',
    text10:'北汽集团项目执行过程中对人力和物力资源进行有效控制成为公司的战略目标。通过引进此系统，在项目管理方面实现全生命周期流程化管控，尤其是对项目所需的人力资源和物力资源的合理配置和调拨方面成效显著。在项目立项过程中由项目经理向资源经理发起资源申请，资源经调拨后可在项目中使用，并且在资源使用过程中进行全程监控...',
    text11:'请正确填写信息，我们会尽快与您联系',
    name:'姓名',
    phone:'手机',
    email:'邮箱',
    position:'职务',
    companyname:'公司名称',
    companyaddress:'公司地址',
    remark:'备注产品需求',
    Case:'申请试用',
    pleaseenter:'请输入',
    Blockchain:'区块链联盟链工具平台',
    Realtimebig:'实时大数据工具平台',
    HRoutsourcingplatform:'人力外包平台',
    dynamic:'动态',
    Strongalliance:'强强联合',
    MicroLitchiTechnology:'微荔科技',
    Payattention:'关注公众号，获取更多资讯',
    Room415:'广东省深圳市南山区茶光路1018号创客公馆415室',
    namefirm:'深圳东方大唐信息技术有限公司',
    Newgenerationenterprise:'新一代企业管理数字化转型解决方案服务商',
    DataOperationCenter:'数据运营中台-产品简介',
    text12:'运营中台通过数据技术，对海量数据进行采集、计算、存储、加工，同时统一标准和口径，并把数据统一并形成标准数据，进行存储，形成大数据资产层。同时，中台集数据管理、数据开发、商业智能（BI）于一体，形成一个数据一站式运营平台，提高系统风险预警，辅助决策的能力。',
    ProductValue:'数据运营中台-产品价值',
    Comprehensive:'全面梳理数据资产构建',
    Globaldatacenter:'全域数据中心',
    Implementation:'落地战略和组织保障',
    Fosterbusinessinnovation:'培养业务创新',
    ofsoil:'的土壤',
    Dataassetmanagement:'数据资产管理',
    Goonline:'在线化',
    talentgrowth:'形成数据人才成长的摇篮',
    Buildthrough:'通过业务的不断滋养打造',
    Businessanddata:'业务和数据',
    ofclosedloops:'的闭环',
    allindustries:'数据中台应用涵盖各个行业',
    img1:'基于数据中台的车联网解决方案-数据加工',
    img2:'基于数据中台的车联网解决方案-用户画像',
    img3:'基于数据中台的车联网解决方案-智能场景推荐',
    img4:'基于数据中台的车联网解决方案-故障诊断',
    Characteristicsofdata:'数据运营中台特点',
    successcases:"成功案例",
    GuangzhouRailway:'广铁计统大数据平台',
    text13:'计统大数据平台结合铁路集团现状，打破数据孤岛，为客户带来全新的数据价值。',
    MeizhouTourism:'梅州旅游大数据平台',
    text14:'旅游大数据平台通过对运营商自有数据和外部数据的融合，实现对客流量、游客来源地、游客属性、游客消费能力、交通出行方式以及实时客流分布的分析。',
    SunYat:'中山大学新媒体监管平台',
    text15:'决策支持：为用户提供危机处理、品牌管理等优质数据源，全面跟踪：根据用户的定义，持续跟踪和盯紧潜在风险事件的发展，发出预警：直观地反映事件的发展态势，必要时发出预警，事件分析：综合评判事件的性质，建立一个评估模型并抓住核心要素和问题根源。',
    Providecustomized:'针对具体客户应用场景，提供做定制化解决方案',
    Difficultproblems:'项目管理中遇到棘手问题',
    ProductLifecycleManagementSubText:'打造一体化协同研发平台，实现项目全生命周期管理解决方案',
    PLMProblemSolving:'PLM系统核心解决问题',
    PLMProblem1:'距离用户远',
    PLMProblemDesc1:'缺少用户研究团队能力，缺少数字化工具应用，无法洞察用户的深层次需求',
    PLMProblem2:'产品效率低',
    PLMProblemDesc2:'产品线庞杂，定制项目多;系统与数据孤岛无法有效进行产品线及项目的财务分析',
    PLMProblem3:'核心技术少',
    PLMProblemDesc3:'研发投入、制定技术规划、技术战略的能力不足;技术成果转化效率低',
    PLMProblem4:'流程断点多',
    PLMProblemDesc4:'研发领域与其他领域未有效拉通，缺少统一的流程框架',
    PLMProblem5:'管理难度大',
    PLMProblemDesc5:'企业规模不断扩张，产品越来越多，但盈利能力越来越低，管理复杂度越来越高',
    SolutionofDongfangDatang:'东方大唐解决方案',
    Unifiedtechnicalarchitecture:'统一的技术架构',
    Unifiedapplication:'统一应用开发平台，减少开发复杂度并支持可扩展性',
    Unifiedmanagement:'• 统一管理发布平台（应用商店）',
    Opensource:'• 开源，自主可控',
    Improveuserperception:'提升用户感知',
    TheUI:'UI的设计以简洁、易用为出发点，以提升用户感知',
    Wholeprocessplanning:'全流程规划',
    Realizebusiness:'站在全流程视角实现业务变革',
    Theapplication:'• 适用领域不仅仅是传统应用功能进行叠加，而是再造整合',
    Benefitfromthreeparties:'三方受益',
    Planningshould:'• 规划要考虑客户、公司、个人三方面受益',
    Planningandprioritization:'• 围绕成本、收益，结合现状进行应用路标规划和优先级确定',
    Ouradvantages:'我们的优势',
    Highlyopensystem:'高度开放的系统',
    Thefirst:'•第一家真正公开源码的平台',
    Supporthorizontal:'•支持企业级流程的横向扩展',
    Supportpersonalized:'•支持现有流程的个性化定制',
    Convenientmicroservices:'方便的微服务',
    Microservicemode:'•微服务模式',
    Providemature:'•提供成熟的系统整合方案',
    AI:'AI、大数据',
    Effectiveapplication:'•AI人工智能的有效应用',
    Intelligent:'•智能绩效考核模型引擎',
    Intelligentmatchmakingengine:'•智能撮合引擎',
    Flexiblecustomization:'灵活的定制',
    Applicable:'•适用不同场景、不同分类',
    Variousauxiliaryfunctions:'•各种辅助功能',
    Applicationscenario:'应用场景',
    RD:'研发',
    Onestop:"一站式研发管理解决方案",
    Cleargrasp:'项目进度清晰掌握，快速跟进产品Bug，多维度统计报表，文件文档有序管理',
    finance:'金融',
    Rapiddevelopment:'金融行业快速发展',
    Incombination:'结合行业需求特点，为银行、保险、证券、互联网金融等客户提供解决方案，帮助金融客户快速部署上线，提升企业竞争力',
    manufacture:'制造',
    Manufacturing:'制造业管理平台',
    competitiveness:'结合行业需求特点，为银行、保险、证券、互联网金融等客户提供解决方案，帮助金融客户快速部署上线，满足业务快速发展的需求，提升企业竞争',
    communication:'通讯',
    communicationfield:'聚焦通信领域',
    Usecontinuous:'运用持续性技术和服务创新，加快通信网络方面的项目建设力度，增长通信公司的业务以及服务范围',
    Softwarelifecycle:'软件生命周期',
    Demandanalysis:'• 需求分析设计',
    Functionaldevelopment:'• 功能开发测试，验证',
    Launch:'• 上线发布',
    Operation:'• 运维迭代',
    agiledevelopment:'敏捷开发',
    Themanagement:"对「需求」「迭代」「缺陷」等环节的管理，将项目拆分成易于实践的任务，从而持续高效地帮助项目落地。",
    Toolsystem:'工具系统',
    Orderlymanagement:'• 有序管理',
    Technologicalinnovation:'• 技术创新，管理变革',
    continuetooptimize:'承接长期目标，持续优化',
    Secondconsultation:'二次咨询',
    Outlinedesign:'概要设计',
    Detaileddesign:"详细设计",
    Electronicprocesspreparation:"流程电子化准备",
    Electronicprocess:'流程电子化',
    ALTimplementationtestonline:'ALT实施测试上线落地',
    Letcustomers:"让客户真正落地项目管理的体系和工具",
    ChinaMerchantsBank:'招商银行',
    text16:'全面提升了招行运行中心项目管理成熟度，实现了从战略目标分解到项目具体实施的全流程管控。',
    text17:'2012年招商银行引进了此套项目管理系统，2012年在运行中心全面推广使用，改变了原有通过Word，Excel、Powerpoint进行项目管理和汇报的方式，实现了项目管理全过程电子化流程化，一期系统主要引进了个人空间、项目管理等基本模块，实现了项目管理从立项到结项全过程的电子化监控，经过3年左右的稳定运行后，逐步增加战略管理模块、人才管理模块、知识管理模块等，进一步完善了项目管理的全生命周期管理，每年中心从战略规划信息的收集、提取录入，到最终定稿，然后层层分解，形成需求，落实到各个部门，再分别创建项目，项目经过制定进度计划渐进明细形成一个个具体任务去执行，完成后反馈到项目进度，最终实现了战略目标的自上而下和自下而上的双向监控。截止到现在，系统运行良好，为运行中心的项目管理工作不断的提供数据和工具支撑。',
    Haier:'海尔集团',
    text18:'跨部门协同项目管理流程完美对接，助力海尔集团全面提升组织项目管理水平。',
    ShenzhenStockExchange:'深圳证券交易所',
    text19:'助力深交所工程管理体系不断升级，3年稳定支持深交所工程管理。',
    LeMall:'乐视商城',
    Internet:'互联网',
    text20:'跨生态协同，多项目联动，完美呈现乐视独有生态系统，实现乐视集团所有项目全闭环生命周期流程化管理',
    HankouBank:'汉口银行',
    text21:"汉口银行科技开发部完善了项目管理流程，组织效率得到了显著提升。",
    LianstackCorporate:"联栈公司",
    text22:'有效实现项目资源合理配置和管控，保证项目有效执行。',
    investment:'投资',
    Bohai:'渤海国际信托有限公司',
    text23:'逐步完善项目管理体系，以项目管理工具为支撑，全面实现单项目全生命周期闭环管理。',
    BaicGroup:'北汽集团',
    text24:'有效实现项目资源合理配置和管控，保证项目有效执行。',
    text25:'制定管理体系设计并开发科技外包信息管理系统，切实解决科技外包管理实际问题。',
    JilinBank:"吉林银行",
    summary:"概述",
    text26:"基于业内先进的项目管理理念结合丰富的项目管理实战经验，东方大唐现在共有的咨询产品包括：PMO建设咨询、项目管理体系建设咨询、敏捷开发管理咨询、科技监管评级支持咨询、项目需求管理咨询、外包管理咨询、和项目风险管理咨询。",
    Specificintroduction:'咨询具体介绍',
    PMOconstructionconsulting:"PMO建设咨询",
    text27:'PMO建设咨询以PMO的职责和定位为基础，结合组织的现实项目管理环境，帮助组织建立平台式项目管理体系，提升项目管理人员素质，完善项目管理系统，全面提升组织项目管理的成熟度。',
    Projectmanagement:'项目管理体系建设咨询',
    text28:'项目管理体系建设从项目管理方法、项目管理细则、项目管理规程、项目管理模板、项目管理指南五个层次进行，涉及工程管理、项目管理和支持管理三个项目管理领域，结合CMMI要求对项目管理体系进行设计和完善。',
    AgileManagementConsulting:"敏捷管理咨询",
    text29:"敏捷管理咨询紧紧围绕敏捷管理原则，敏捷项目团队建设、敏捷管理框架流程实施，对组织相关人员进行培训并帮助组织建设敏捷管理环境和系统，实现敏捷管理的自动化。",
    text30:"科技监管评级咨询以银保监会发布的相关指导和文件为基础，结合每年监管的不同要求和细节，进行应对方案的分析和制定，并在监管评级期间协助做好现场应答及文件准备工作。",
    text31:"项目需求管理咨询监理项目需求管理办法、项目需求管理细则、项目需求管理相关规程、项目需求管理相关模板，明确需求管理的方法和需求申请和评审方案，帮助组织实现高效合理的需求管理。",
    text32:"围绕银保监会对外包风险的要求，从外包的供应商管理、外包人员管理、外包合同管理、外包风险管理四大领域帮助组织建立完善的外包管理体系及外包管理系统规划，在保证组织外包管理合规的前提下控制外包风险提升外包管理效率。",
    text33:"项目风险管理咨询以执行人员、风险管理人员、风险审计人员不同的风险管理分工为基础形成风险管理的三道防线，明确三类人员的职责和工作内容，建立安全、风险、审计为基本内容的风险管理制度，实现项目风险管理的提升。",
    Scientific:'科技监管评级咨询',
    Projectdemand:"项目需求管理咨询",
    Outsourcing:'外包管理咨询',
    management1:'项目风险管理',
    ECPP:'企业内训',
    text34:'企业内训为企业的管理者（包括管理人员、项目经理、PMO人员）量身定制培训课程，覆盖6大管理领域39个专项内容，为企业的日常管理、项目管理人员队伍素质和能力保驾护航。',
    Trainerintroduction:"培训老师介绍",
    TeacherBao:'包老师',
    text35:'东方大唐资深咨询师，曾服务于招商银行。主要负责招商银行PMO管理，持有PMP、PRINCE2、ITIL、等管理证书。为深圳区块链技术研究协会负责人。专注于项目管理系统建设以及项目管理系统的微服务、智能化、区块链化研究和实践。',
    MissGao:'高老师',
    text36:'东方大唐资深咨询师，招商银行、兴业银行、泰康人寿等企业项目管理顾问。具有多年项目管理咨询培训实战经验，以及企业IT项目管理体系建设、需求管理经验。',
    MissAn:'安老师',
    MissWang:'王老师',
    text37:'东方大唐高级咨询师，具有丰富的项目管理经验，持有PRINCE2项目管理证书。曾经管理招商银行项目管理系统项目、北京汽车集团项目管理平台项目、广州银行外包管理平台项目等。',
    text38:"东方大唐资深咨询师，曾经服务招商银行、周大福集团、奋达集团等大型企业，专注于组织PMO建设、项目管理、组织建设和外包管理等领域，具有丰富的项目时间经验和咨询经验，曾为多家知名企业进行企业内训服务。",
    Projectmanagement:'项目管理培训课程表',
    number:'编号',
    Curriculumsystem:'课程体系',
    Includingspecial:'包含专项内容',
    Suggestions:'培训对象建议',
    Trainingdays:'培训天数',
    management1:"管理人员",
    projectmanager:'项目经理',
    personnel:"PMO人员",
    Personnelquality:'人员素质提升',
    Development:"个人素质和职业发展的思考",
    Methods:"个人素质提升的方法",
    Comprehensive:"个人素质的全方位评估",
    qualityimprovement:"个人素质提升的实践",
    days:"天",
    Managementpersonnel:"管理人员思想建立",
    Managementthinking:"管理人员思维方法",
    Ideas:"管理工作提升思路",
    Fourmanagement:"管理人员四大管理技能",
    Officeskills:"管理人员办公技能",
    Guidance:"管理领域指导",
    ManagementManager:"管理管理者",
    Managementorganization:"管理组织（团队）",
    Managementandorganization:"管理组织工作",
    Managementorganizationpersonnel:"管理组织人员",
    managementinformation:"管理信息",
    Establishmanagement:"建立管理平台系统",
    Importance:"项目管理的重要性",
    Pmpknowledge:"pmp知识体系",
    Prince2:"prince2知识体系项目需求管理实践",
    ProjectCost:"项目成本管理实践",
    ProjectTime:"项目时间管理实践",
    ProjectQuality:"项目质量管理实践",
    ProjectResource:"项目资源管理实践",
    ProjectCommunication:"项目沟通管理实践",
    ProjectRisk:"项目风险管理实践",
    ProjectStakeholder:"项目干系人管理实践",
    Projectprocurement:"项目采购管理实践",
    ProjectConfiguration:"项目配置管理实践",
    Practicalcases:"项目管理实战案例",
    AgileProject:"敏捷项目管理",
    Agilemanagement:"敏捷管理知识",
    AgileManagement:"敏捷管理团队",
    AgileRequirements:"敏捷需求管理",
    AgileProcess:"敏捷过程管理",
    PMOmanagement:"PMO建设管理",
    PMOplanning:"PMO 建设规划",
    PMOconstruction:"PMO 组织建设",
    Multiproject:"多项目及多项目信息管理",
    ExcellenceConstruction:"卓越中心建设",
    informationmanagement:"单项目控制指导及信息管理",
    Outsourcer:"外包商（外包人员）管理",
    Note1:"注：培训内容包括6 大课程体系，39 项专项内容。",
    AD:"应用开发",
    text39:"东方大唐拥有专业化的应用开发服务团队近百人，长期为客户世界500强客户提供端到端的应用软件开发和维护服务。凭借在CMMI和ISO9001质量标准和流程认证基础上形成的系统化、成熟的方法论，以及多年来的丰富项目实践经验，东方大唐能够为客户提供包括：系统开发、平台开发、APP开发、项目定制等全栈服务。",
    Serviceadvantages:"服务优势",
    text40:"全覆盖的信息解决方案：提供金融、互联网、高科技、电信、新零售、电商等覆盖全行业的解决方案。",
    text41:"全链全生命周期开发服务：涵盖开发流程中的各个阶段，包括：需求分析、设计、编码、测试、部署以及实施后的支持。",
    text42:"多样化的定制开发服务：东方大唐借多年的IT行业积累，为客户提供多样化、专业化的定制化开发服务",
    Industrypracticecases:"行业实践案例",
    Hotlineconsultation:"热线咨询",
    ConsultQQ:"咨询QQ",
    Customerserviceemail:"客服邮箱",
    ConsultingWeChat:"咨询微信",
    content1:"深圳东方大唐信息技术有限公司（简称东方大唐）是新一代企业管理数字化转型解决方案服务商。公司创立于2012年，注册资本5000万，具备国家双软企业、高新技术企业、ISO质量体系和CMMI等资质，为金融、制造、通信、医疗等跨行业大型集团企业及政府客户提供了包括数字化平台、软件产品、应用开发、管理咨询及系统集成等的专业服务，协助客户构建以大数据、区块链及人工智能为核心的企业数字化管理平台体系。",
    content2:"东方大唐践行“专属定制”的系统开发思路，依托自主研发的",
    content3:"微服务中台，融合风险管控价值提升理念，打造最契合中国企业管理思维的业务管理引擎。公司主推项目管理、外包管理、DevOps、CRM、SRM、RPA、合同管理、绩效管理、度量管理、风控合规管理等企业核心业务管理解决方案。",
    content4:"东方大唐秉承以客户为中心的经营理念，贯彻执行“一体两翼”的战略，3+3模式将技术与企业业务场景有机融合，面向客户开放所有核心代码，深度整合替换封闭式系统管理工具，为客户打造“自主可控”的数字化业务管理平台，帮助客户从优秀到卓越。",
    content5:"公司总部位于深圳，有北京、上海、武汉、广州、包头等分支机构，员工数超过1200人，南京、长沙、新疆、青海等地设有办事处。",
    // content6:"东方大唐自2012年创立以来，始终以项目管理为核心，经历了从咨询服务到工具的转型升级，2020年，公司名称也更改为深圳东方大唐信息技术有限公司，明确了依托微服务平台为基础，完全开放，持续创新的生态战略。疫情肆虐，但东方大唐发展的脚步并未停歇，我们布局五地分公司，引入更多合伙人，倒逼原始骨干二次创业再出发，培养新的管理核心逐步接管东方大唐。",
    // content7:'2021年，我们正式开启“一体两翼”新战略，一切以项目管理为核心，促业绩增长和技术创新。布局人力外包业务和数字化转型两个新业态，锁定大客户做大做强。人力外包业务我们联合南京分公司，在现有百人规模的基础上要达到规模双倍增长。数字化转型业务主要依靠广州分公司，联合BAT生态体系，从业务场景入手，规划加工具落地，孵化数据中台产品线、SRM、风控平台等。',
    // content8:"一体两翼新战略，依旧围绕大客户深度合作，京东方集团、北汽集团、华润集团、招商局集团、三一集团、中煤集团、中燃集团、武汉农商、青海农信、新疆农信、太平保险。我们配套北京、武汉、深圳三地研发中心，坚持“无尽开放、持续创新”的理念，一切以服务为根本点，陪客户一起从优秀到卓越。",
    // content9:"2022年，我们会引入更多的合伙人，也会为您提供更多的机会和平台，你准备好了吗？",
    content10:"东方大唐秉承以客户为中心的经营理念，贯彻执行“一体两翼”的战略，配备专业高效的服务团队，通过产学研合作，将技术与企业业务场景有机融合，面向客户开放所有核心代码，深度整合替换封闭式系统管理工具，为客户打造“自主可控”的数字化业务管理平台，帮助客户从优秀到卓越。",
    Companyadvantages:"公司优势",
    Enterprisewitness:"企业见证",
    MideaRealEstate:"美的置业",
    ChinaMerchantsGroup:"招商局集团",
    TaipingFinance:"太平金融",
    ChinaMerchantsBank:"招商银行",
    SanyHeavyIndustry:"三一重工",
    Softening:"软著",
    AltaVM:"Alta-VM科技外包管理系统",
    AltaPM:"Alta-PM项目管理系统",
    AltaALM:"Alta-ALM研发管理系统",
    honor:"荣誉",
    Informationtechnology:"信息技术应用创新工作",
    Memberunits:"委员会会员单位",
    Memberof:"深圳软件协会会员单位",
    qualifications:"资质",
    CMMI3certificate:"CMMI3证书",
    ISO9001certificate:"ISO9001认证证书",
    Identification:"高新技术企业认定",
    patent:'专利',
    alliancechain:"基于区块链联盟链的",
    Science:"科技外包管理系统",
    ContractAbidingAndTrustworthyEnterprises:"重合同守信用3A级企业",
    MeiyunZhishuDiamondLevelPartner:"美云智数钻石级合作伙伴",
    LaborDispatchBusinessLicense:"劳务派遣经营许可证",
    Massdata:"海量数据产品兼容互认证明",
    ISO20000Information:"ISO20000信息技术服务管理体系认证证书",
    ISO27001Information:"ISO27001信息安全管理体系认证证书",
    Jushandatabase:"产品兼容互认证明（巨杉数据库）",
    Softwareproduct:"软件产品证书",
    EnterpriseCertificate:"软件企业证书",
    IndustryAssociation:"深圳市软件行业协会会员单位",
    WorkingCommittee:"信息技术应用创新工作委员会会员单位",
    Kingdee:"产品兼容互认证明(金蝶)",
    ISO9001Quality:"ISO9001质量管理体系",
    OrientalDatangPublicity:"东方大唐宣讲会—全域数据中台方案篇",
    Welcomefriendswho:"欢迎对此次宣内容讲有任何疑问的朋友私信咨询~",
    LectureFull:"东方大唐宣讲会—项目全流程管理解决方案篇",
    Thespringsowing:"春播活动圆满结束，宣讲内容视频已经制作上传。欢迎大家关注收藏",
    OutsourcingManagement1:"东方大唐宣讲会—IT外包管理系统解决方案篇",
    text40:"春播活动圆满结束，宣讲内容视频会陆续制作上传。欢迎大家关注收藏~",
    customizedproducts:"京东方项目管理系统从标准化到定制化产品之路",
    text41:"东方大唐在其标准化产品的基础上，持续为京东方提供着专业的定制化需求开发服务，以流程引擎为驱动，动态表单为业务载体，实现产品与业务的紧密结合，提高整体工作效率。",
    WuhanRuralCommercial:"东方大唐助力武汉农商行建立科技外包管理平台",
    text42:"东方大唐凭借着“专属定制、强化落地”的思路，成功与武汉农商行达成友好合作。帮助武汉农商行建立科技外包管理平台并以工具为载体将体系深化落地。",
    WiileadnformationInnovation:"东方大唐Wiilead信创项目管理平台【推介】",
    text43:"Wiilead信创项目管理平台通过数字化的方式实现了对信创项目实施过程的全面管理。从项目立项、需求管理、计划管理、资源调度、问题风险管理、结项管理、度量管理等全过程进行监控。",
    domesticSaaSranking:"国内SaaS排行榜TOP30",
    text44:"内SaaS TOP30排行，2019《互联网周刊》&amp;eNet研究院选择排行",
    ManagementSystemReconstruction:"东方大唐助力中煤基建项目管理系统重构",
    text45:"日前，东方大唐凭借系统的专业性、稳定性、灵活性、易用性，以及丰富的实施经验，与中煤信息技术(北京)有限公司（以下简称：中煤）达成合作关系，助其搭建基建项目管理",
    HowtheAssetManagement:"资产管理系统如何解决企业资产管理的业务痛点",
    text46:"随着企业经营范围的不断扩大，企业每年都投入大量的资金来购置各种资产，包括有形资产与无形资产等；随着时间的推移，资产的管理也面临难题。",
    helpingdomesticsoftware:"东方大唐成为信创工委会会员单位，助力国产软件创新",
    text47:"在信息技术应用创新蓬勃发展之时，深圳东方大唐信息技术有限公司（简称东方大唐）正式加入信息技术应用创新工作委员会（会员编号：1698），东方大唐成为2021年第三批加入信创工委会的企业之一。",
    KingdeeTianyan:"金蝶天燕与东方大唐完成产品兼容性互认证",
    text48:"近日，深圳市金蝶天燕云计算股份有限公司(简称:金蝶天燕)的金蝶Apusic应用服务器软件V9.0/V10与深圳东方大唐信息技术有限公司(简称:东方大唐)的Wiilead项目管理平台已",
    ChineseSaaSenterpriseswithdeviation:"跑偏的中国SaaS企业",
    text49:"很早就想写一篇关于中国的SaaS企业的文章，想分析一下SaaS企业给中国软件市场添乱的三宗罪，终于昨天看到了腾讯SaaS加速器的文章，我想这个文章可以发出来了。",
    HuaweiHongmengsystem:"关于wiilead项目管理系统APP与华为鸿蒙系统适配性的说明",
    text50:"昨日，华为发布了鸿蒙系统(HarmonyOS)并开放了一些手机型号的公测，部分华为手机用户希望尝鲜升级体验，并关心wiilead项目管理系统APP与鸿蒙系统的适配性情况。经测试，现有的wiilead项目管理系统安卓客户端可以在鸿蒙系统上正常使用，整体运行流畅。",
    DongfangDatangHelps:"东方大唐助力青海农信项目管理建设",
    text51:"东方大唐凭借着“专属定制、强化落地”的思路，成功与青海农信达成友好合作。帮助青海农信建立项目管理体系并以工具为载体将体系深化落地。",
    ImprovementSystemTraining:"东方大唐助力武汉农商软件实施过程管理项目--项目经理素质提升系统培训",
    text52:"9月19日-20日晚上6:30—8:30，在武汉农商的大楼7楼会议室中项目经理素质提升的相关培训正在紧张有序的举行。此次的项目经理素质提升系统培训是软件实施过程管理项目中，项目经理素质提升专题的一项工作包中的工作内容，目的是通过对项目经理的培训，提升项目经理的素质和知识水平保证项目管理制度的落地和项目管理系统的高效使用。",
    Altaplatformunder:"东方大唐旗下Alta平台助力吉林银行项目管理体系优化",
    text53:"为规范和指导项目管理活动的开展，优化项目管理流程，降低项目风险，提升项目质量，促进吉林银行项目管理水平的提高，最大程度地满足监管要求，开展此次项目管理咨询服务。",
    ImplementCMMISystem:"东方大唐携手万科物业落地CMMI体系",
    text54:"随着信息技术对企业重要性不断的提升，成熟的软件开发过程管理（CMMI）成为对企业提升信息技术管理的重要条件，万科物业的高层对产品研发过程提出标准化要求，所以",
    exchangebigdata:"上海知识产权交易中心信息部到招银云创交流考察大数据",
    text55:"2月15日上午，上海知识产权交易中心信息部到招商银行下属全资子公司招银云创交流考察基于大数据平台的精准营销解决方案，招银云创研发中心总经理张子玮全程陪同，并将招商银行在零售业务大数据方面的经验做了分享。随后双方就知识产权交易的大数据平台规划、平台模式、未来前景等方面进行了深入探讨和交流。参加此次交流的还有上交会成员单位上海辉罡信息科技有限公司总经理张涛、东方大唐CEO包秀明等一众随行人员。",
    GroupProjectManagement:"东方大唐联手北汽集团推动集团项目管理信息化建设",
    text56:"基于深圳市东方大唐信息咨询有限公司在项目管理咨询领域与建设方面多年的丰富成功经验，于2017年11月30日，东方大唐和北京汽车集团有限公司就北汽集团项目管理系统的建设达成合作共识，并于2018年1月3日正式进行项目建设。",
    projectmanagerteaparty:"“人人都是项目经理”茶话会",
    text57:"项目经理的职责是什么？是做管理的，做协调；没有永远不变项目，项目是不断变化的，成本、范围、进度如何平衡？项目管理、沟通管理、人员管理、资源管理、干系人管理",
    trainingactivities:"自我管理研讨培训活动",
    text58:"王阳明在心学中说道：“知之真切笃实处，即是行。行之明觉精查处，即是知。”说的就是对管理的要求，知的含义就是我们在头脑中形成的管理方案，行就是我们具体措施。知行合一现在看来不是一件简单的事情，很多时候我们的方案提出来觉得很有道理...",
    ProjectManagementSystem:"项目管理系统建设二期项目优秀团队表彰大会",
    text59:"公司总经理包秀明先生出席了颁奖仪式，并对京东方科技集团项目管理系统建设二期实施服务项目工作进行回顾和总结，肯定此项目中里取得的一系列重大成就与进步。此外，并重申了我们的使命与愿景，他表示，“我们的使命是为客户提供专业的管理工具！”他强调",
    contestsuccessfully:"2018中国“迎新杯”项目管理演讲比赛在深圳成功落幕",
    text60:"2018年12月31日，由东方大唐和强矩阵共同主办的第一届中国“迎新杯”项目管理演讲比赛在深圳成功落幕，多位优秀选手表现出色，其中有三名选手获得最佳风采奖，两名选",
    strategiccooperation:"东方大唐和PMI中国成为战略合作",
    text61:"近日，东方大唐CEO包秀明先生在北京拜访了PMI大中华区最高领导—PMI（中国）董事总经理陈永涛先生，两人在PMI中国办公室合影留念...",
    managementSaaS:"项目管理SaaS之路还有多远？",
    text62:"随着大数据、云计算的兴起，IaaS、PaaS、SaaS、CaaS、BaaS这些词从陌生变成热词，云计算市场规模从几十亿到几百亿乃至千亿级别，每年两位数的增速，让风投嗅到",
    ShenzhenHeadquarters:"深圳 | 总部",
    ThedawnofXiliStreet:"深圳市南山区西丽街道曙光",
    CommunityChaguang:"社区茶光路1018号创客公馆415",
    MsAn:"安女士",
    WuhanCenter:"武汉 | 研发中心",
    HongshanDistrict:"湖北省武汉市洪山区光谷大道",
    No62OpticsValley:"62号光谷总部国际3栋401B",
    MrRen:"任先生",
    Guangzhou:"Guangzhou",
    TianheDistrictGuangzhou:"广州市天河区科韵路12号",
    Oneofthe28th:"之一28、29楼自编之33号",
    MrChen:"陈先生",
    Beijing:"北京",
    YizhuangHongdaMiddle:"北京市大兴区亦庄宏达中路",
    Room320:"16号宏达三鼎320室",
    MrHuang:"黄先生",
    Hangzhou:"杭州",
    ZhejiangProvince:"浙江省杭州市上城区钱江",
    BusinessCenter:"国际商务中心1018-1室",
    MrDai:"代先生",
    Shanghai:"上海",
    MinhangDistrictShanghai:"上海市闵行区罗锦路218号",
    Room326:"麦腾未来城326室",
    MrLi:"李先生",
    Baotou:"包头",
    AutonomousRegion:"内蒙古自治区包头市",
    QingshanDistrict:"青山区育才小区1-11",
    MsXue:"薛女士",
    Shenzhen:"深圳",
    Wuhan:"武汉",
    Frontenddevelopment:"前端开发",
    Jobresponsibilities:"岗位职责：",
    Responsibleforthe:"1、负责PC和移动端(非原生)开发的研发工作；",
    designrequirements:"2、根据产品设计需求实现、维护及优化WEB前端页面和业务功能；",
    Pagedesignbased:"3、基于HTML、DIV+CSS和Javascript进行页面设计，编写可重用的前端界面组件，积累公司前端组件库;",
    technicalarchitecture:"4、在开发中发现系统潜在问题，优化系统功能代码，积极参与产品、功能与技术架构的改进;",
    newtechnology:'5、现有产品前端界面持续改进及参与Web前沿技术研究和新技术调研。',
    Jobrequirements:"任职要求:",
    Atleast3years:"1、至少3年实际项目前端开发经验，能独立完成PC和移动端开发任务;",
    ProficientinHTML5CSS:"2、精通HTML5CSS.Javascript，DOM，有扎实的Javascript基础(作用域继承，闭包，面向对象设计等);",
    Familiarwithmodularization:"3、熟悉模块化、前端编译和构建工具，熟悉webpack等构建工具;",
    Proficientinfront:"4、熟练掌握前端优化技术，对组件化，模块化开发有深刻理解，能熟练使用Vue等主流MVVM框架，熟悉交互设计;",
    FamiliarwithElementUl:"5、熟悉ElementUl、iView、Bootstrap、Vux、WeUl等常见UI组件;",
    Experienceinsmall:"6、有小程序、Android和IOS封装H5开发经验者优先;",
    Goodcommunication:"7、具备良好的沟通协调能力，有独立分析问题和解决问题的能力。",
    Javadevelopmentengineer:"java开发工程师",
    javatext1:"1. 专科以上学历，计算机相关专业，3年以上开发经验；",
    javatext2:"2. java基础扎实、掌握多线程、设计模式；",
    javatext3:"3. 熟练使用Spring Cloud、Spring boot、Mybatis、Spring security等开源框架；",
    javatext4:"4. 有中大型产品或项目开发实施经验，对缓存设计、消息队列有一定了解；",
    javatext5:"5. 熟悉TCP/IP、HTTP等网络协议；",
    javatext6:"6. 熟悉常用Linux命令，能独立部署开源中间件及其它应用服务；",
    javatext7:"7. 抗压能力强，有想法，责任心强，工作积极主动；善于学习和交流，挑战未知，具有良好的团队合作精神；",
    javatext8:"有金融项目经验者优先。",
    ContactTangJing:"联系人：唐静",
    WeChatsynchronization:"微信同步",
    website:"网址",
    Address401:"地址：武汉市洪山区光谷总部国际3栋4楼401",
    qx:"英文",
    Previousarticle:"上一条",
    Next:"下一条",
  }
}