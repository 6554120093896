<template>
  <section>
    <transition name="fade" mode="out-in">
      <router-view :key="key"></router-view>
    </transition>
  </section>
</template>

<script src="./static/jquery-1.12.4.js"></script>
<script>
export default {
  name: "AppMain",
};
</script>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    },
  },
};
</script>


