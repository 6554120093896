import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const createRouter = () => new Router({
  mode: 'history',
  routes: [
    { path: '', redirect: "home" },
    { path: '/home', component: () => import('@/pages/HomePage'), name: 'HomePage', meta: { requireAuth: false } },
    { path: '/solution/project', component: () => import('@/pages/solution/ProjectManagement'), name: 'ProjectManagement', meta: { requireAuth: false }, },
    { path: '/solution/development', component: () => import('@/pages/solution/RDManagement'), name: 'RDManagement', meta: { requireAuth: false }, },
    { path: '/solution/outsourcing', component: () => import('@/pages/solution/OutsourcedManagement'), name: 'OutsourcedManagement', meta: { requireAuth: false }, },
    { path: '/solution/productLifecycle', component: () => import('@/pages/solution/ProductLifecycleManagement'), name: 'ProductLifecycleManagement', meta: { requireAuth: false }, },
    { path: '/case/customer', component: () => import('@/pages/case/CustomerCase'), name: 'CustomerCase', meta: { requireAuth: false }, },
    { path: '/service/management', component: () => import('@/pages/service/ManagementConsulting'), name: 'ManagementConsulting', meta: { requireAuth: false }, },
    { path: '/service/enterprise', component: () => import('@/pages/service/EnterpriseTraining'), name: 'EnterpriseTraining', meta: { requireAuth: false }, },
    { path: '/service/dev', component: () => import('@/pages/service/ApplicationDevelopment'), name: 'ApplicationDevelopment', meta: { requireAuth: false }, },
    { path: '/products/platform', component: () => import('@/pages/products/ProductPlatform'), name: 'ProductPlatform', meta: { requireAuth: false }, },
    { path: '/products/operation', component: () => import('@/pages/products/DataOperationCenter'), name: 'DataOperationCenter', meta: { requireAuth: false }, },
    { path: '/products/hroutsourcing', component: () => import('@/pages/products/HrOutsourcing'), name: 'HrOutsourcing', meta: { requireAuth: false }, },
    { path: '/products/bigdata', component: () => import('@/pages/products/BigData'), name: 'BigData', meta: { requireAuth: false }, },
    { path: '/products/blockchain', component: () => import('@/pages/products/BlockChain'), name: 'BlockChain', meta: { requireAuth: false }, },
    { path: '/aboutUs/profile', component: () => import('@/pages/aboutUs/CompanyProfile'), name: 'CompanyProfile', meta: { requireAuth: false }, },
    { path: '/aboutUs/advantage', component: () => import('@/pages/aboutUs/CompanyAdvantage'), name: 'CompanyAdvantage', meta: { requireAuth: false }, },
    { path: '/aboutUs/qualification', component: () => import('@/pages/aboutUs/CompanyQualification'), name: 'CompanyQualification', meta: { requireAuth: false }, },
    { path: '/aboutUs/news', component: () => import('@/pages/aboutUs/CompanyNews'), name: 'CompanyNews', meta: { requireAuth: false }, },
    { path: '/aboutUs/news/:id', component: () => import('@/pages/aboutUs/CompanyNewsDetails'), name: 'CompanyNewsDetails', meta: { requireAuth: false }, },
    { path: '/aboutUs/contact', component: () => import('@/pages/aboutUs/ContactUs'), name: 'ContactUs', meta: { requireAuth: false }, },
    { path: '/aboutUs/join', component: () => import('@/pages/aboutUs/JoinUs'), name: 'JoinUs', meta: { requireAuth: false }, },
  ]
})

const router = createRouter()


export default router
