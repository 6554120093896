import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/index.css'
import '@/assets/scss/index.scss'
import axios from 'axios'
import '@/utils/index.js'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$axios=axios
import i18n from './lang'
Vue.use(ElementUI, {
  size: 'medium',
  i18n: (key, value) => i18n.t(key, value)
})

import moment from 'moment';
Vue.prototype.$moment = moment;

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
